<template>
  <div>
    <aad-title>CME Credit Claims</aad-title>
    <div v-if="isLoggedInUserVerified">
      <div class="alert alert-primary mb-4" role="alert">
        <div>
          <aad-icon
            name="warning-circle-filled"
            class="warning-icon"
          ></aad-icon>
          Please also take a minute to fill out the
          <router-link
            :to="{ name: 'CMESurvey', params: { id: 'OVERALL' } }"
            class="reg-text-link-light-bg font-weight-bold"
          >
            Overall Evaluation </router-link
          >.
        </div>
      </div>
      <p class="font-weight-bold">
        Use the search bar below to find the sessions that you attended. Click
        the claim button and fill out the form to submit for your credit.
      </p>
      <input
        id="txtSearch"
        type="text"
        placeholder="Search Sessions"
        aria-label="Search"
        class="form-control"
        v-model="searchText"
      />
      <div class="row">
        <div class="col-md-6 order-md-2 p-2" v-if="this.currentMeeting">
          <h3 class="p-2">
            Claimed Credits ({{ getNumberOfCreditsClaimed() }} /
            {{ currentMeeting.CmeMaxLimit }})
          </h3>
          <div v-if="allSessions && allSessions.length > 0">
            <div
              class="meeting-app-info-block"
              v-for="limit in currentMeeting.CmeDailyLimits"
              :key="limit.Day"
            >
              <div class="meeting-app-info-header row">
                <h4>
                  {{ getLimitDate(limit) }} - ({{
                    getNumberOfCreditsClaimed(getLimitDate(limit))
                  }}
                  / {{ limit.CreditAmount }})
                </h4>
              </div>
              <div class="meeting-app-info-body p-1">
                <div v-if="cmeSessions && cmeSessions.length > 0 && getNumberOfCreditsClaimed(getLimitDate(limit)) > 0">
                  <CME-session-card
                    :session="session"
                    class="meeting-app-info-item"
                    v-for="session in cmeSessions.filter((session) =>
                    isCreditClaimActiveBySessionCode(session.SessionCode) &&
                    getSessionDate(session) == getLimitDate(limit)
                  )"
                    :key="session.id"
                  ></CME-session-card>
                </div>
                <div v-else class="p-2 font-weight-bold">No claimed CME credits.</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 order-md-1 p-2">
          <div
            class="meeting-app-info-block"
            v-if="allSessions && allSessions.length > 0"
          >
            <div class="meeting-app-info-header row">
              <h4>All Sessions</h4>
            </div>
            <div class="meeting-app-info-body p-1">
              <div v-if="cmeSessions && cmeSessions.length > 0">
                <div
                  v-for="session in cmeSessions"
                  :key="session.id"
                  class="meeting-app-info-item"
                >
                  <CME-session-card :session="session"></CME-session-card>
                </div>
              </div>
              <div v-else>No Available Sessions</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <p>You must be verified on site in order to claim CME credits.</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CMESessionCard from "@/components/CMESessionCard.vue";
import { formatDate, formatSecondsToDate } from "@/mixins/formatDate.js";

export default {
  data() {
    return {
      searchText: "",
    };
  },
  mounted() {
    this.getSessions();
    this.getSpeakers();
    this.getCMEClaims();
  },
  methods: {
    getSessions() {
      this.$store.dispatch("loadSessions");
    },
    getSpeakers() {
      this.$store.dispatch("loadSpeakers");
    },
    getCMEClaims() {
      this.$store.dispatch("loadAttendeeCMEClaims");
    },
    getLimitDate(limit) {
      return formatDate(new Date(limit.Day));
    },
    getSessionDate(session) {
      return formatSecondsToDate(session.StartTime.seconds);
    },
  },
  components: {
    CMESessionCard,
  },
  computed: {
    ...mapGetters([
      "allSessions",
      "getSessionById",
      "getSpeakerById",
      "allSpeakers",
      "isLoggedIn",
      "getMeetingCode",
      "loggedInUser",
      "currentMeeting",
      "isCreditClaimActiveBySessionCode",
      "isLoggedInUserVerified",
      "getNumberOfCreditsClaimed",
    ]),
    cmeSessions() {
      return this.allSessions.filter((session) => {
        return (
          session.Name.toLowerCase().includes(this.searchText.toLowerCase()) ||
          session.SessionCode.toLowerCase().includes(this.searchText.toLowerCase())
        );
      });
    },
  },
};
</script>

<style scoped>
.warning-icon {
  width: 24px;
  height: 24px;
}
</style>
